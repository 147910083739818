var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "big" },
    [
      _c("el-row", [
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("el-col", { attrs: { xs: 24, lg: 16, md: 16 } }, [
              _c(
                "div",
                { staticClass: "car-left" },
                [
                  _c("el-row", [
                    _c(
                      "div",
                      [
                        _c("el-col", { attrs: { xs: 20, lg: 12, md: 12 } }, [
                          _c("div", { staticClass: "text" }, [
                            _c("h4", [
                              _vm._v("您好，欢迎使用AD后台管理系统！"),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "tips-text" }, [
                              _c("i", { staticClass: "el-icon-sunny el-icon" }),
                              _vm._v(" "),
                              _c("span", [_vm._v("请开始您一天的工作吧")]),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "shadow" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.toolCards, function (card, key) {
              return _c(
                "el-col",
                {
                  key: key,
                  attrs: { span: 4, xs: 8 },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.toTarget(card.name)
                    },
                  },
                },
                [
                  card.name !== _vm.$route.name
                    ? _c(
                        "el-card",
                        {
                          staticClass: "grid-content",
                          attrs: { shadow: "hover" },
                        },
                        [
                          _c("i", {
                            class: card.icon,
                            style: { color: card.color },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(card.label))]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }