import _objectSpread from "/Users/adway/Documents/agora.io/ad-manage-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Welcome from './welcome.vue'
import { mapGetters } from 'vuex';
import welcome from './welcome.vue';
export default {
  name: 'Dashboard',
  components: {
    welcome: welcome
  },
  data: function data() {
    return {
      toolCards: [{
        label: 'AD配置中心',
        icon: 'el-icon el-icon-user',
        name: 'projects',
        color: '#ff9c6e'
      }, {
        label: '用户管理',
        icon: 'el-icon el-icon-user-solid',
        name: 'user',
        color: '#ffd666'
      }, {
        label: '操作记录',
        icon: 'el-icon el-icon-s-management',
        name: 'operation',
        color: '#b37feb'
      }]
    };
  },
  computed: _objectSpread({}, mapGetters('user', ['userInfo'])),
  methods: {
    toTarget: function toTarget(name) {
      this.$router.push({
        name: name
      });
    }
  }
};